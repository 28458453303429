<template>
    <div class="phi-schedule-picker">
        <select
            v-model="schedule"
            :disabled="disabled"
            @input="$emit('input', schedule)"
        >
            <option :value="null">{{ $t('noun.righNow') }}</option>
            <option v-for="time in hours" :value="time.value" :key="time.label">{{ time.label }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'phi-schedule-picker',

    props: {
        value: {},
        disabled: {
            default: false
        }
    },

    computed: {
        schedule: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
                this.$emit('change', value);
            }
        },

        hours() {
            var retval = [];
            for (var hour = 5; hour <= 23; hour++) {
                var hour12 = hour > 12 ? hour-12 : hour;
                var ampm   = hour > 12 ? 'pm' : 'am';

                retval.push(
                    {
                        label: hour12 + ':00 ' + ampm,
                        value: hour + '00'
                    },
                    {
                        label: hour12 + ':30 ' + ampm,
                        value: hour + '30'
                    }
                );
            }

            return retval;
        }
    }
}
</script>

<style lang="scss">
.phi-schedule-picker select {
    border: 0;
    margin: 0;
    padding: 0;

    font-size: 12px;
    color: #777;
}
</style>
