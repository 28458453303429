<script>
import PhiPostNotificationDestination from '../../../components/Phi/Post/Notification/Destination.vue';
import app from '../../../store/app.js';

export default {
    name: "state-post-notification-settings",
    components: {PhiPostNotificationDestination},

	props: {
		personId: {
			default() {
				return null;
			}
		},

        isGeneral: {
            type: Boolean,
            default: false
        }
	},

    data() {
        return {
            destinations: [],

            formIsOpen: false,
            newDestination: {
                transport: "email",
                destination: null,
                isEnabled: true,
                schedule: null
            }
        }
    },

    computed: {
        transports() {
            var transports = {};
            for (var i = 0; i < this.destinations.length; i++) {
                if (typeof transports[this.destinations[i].transport] == "undefined") {
                    transports[this.destinations[i].transport] = {
                        name: this.destinations[i].transport,
                        destinations: []
                    };
                }

                // Ignore generic APN and GCM destinations
                if ( (this.destinations[i].transport == 'apn' || this.destinations[i].transport == 'apn' ) && !this.destinations[i].transport.id ) {
                    continue;
                }
                transports[this.destinations[i].transport].destinations.push(this.destinations[i]);
            }

            return transports;
        },

        sortedDestinations() {
            return this.destinations.sort((a,b) => {
                var aTitle = this.$t('transport.'+a.transport).toLowerCase();
                var bTitle = this.$t('transport.'+b.transport).toLowerCase();

                if (aTitle < bTitle) {
                    return -1;
                }

                if (aTitle > bTitle) {
                    return 1;
                }

                return 0;
            });
        }
    },

	mounted() {
        this.loadDestinations();
    },

    methods: {
        loadDestinations() {
            var collectionUrl = this.isGeneral ? 'post/notification/destinations' : `people/${this.personId}/notification/destinations`;
            app.api
                .get(collectionUrl)
                .then(destinations => this.destinations = destinations);
        },

        save(destination) {
            if (!destination.id) {
                return this.create(destination);
            }

            var destinationUrl = this.isGeneral ? `post/notification/destinations/${destination.id}` : `people/${this.personId}/notification/destinations/${destination.id}`;
            var collectionUrl = this.isGeneral ? 'post/notification/destinations' : `people/${this.personId}/notification/destinations`;
            return app.api
                .put(destinationUrl, destination);
        },

        create(destination) {
            var collectionUrl = this.isGeneral ? 'post/notification/destinations' : `people/${this.personId}/notification/destinations`;
            app.api
                .post(collectionUrl, destination)
                .then(destination => {
                    this.formIsOpen = false;
                    this.newDestination.destination = null;
                    this.loadDestinations();
                });
        },

        remove(destination) {
            if (!confirm( this.$t('noun.deleteEmail') )) {
                return;
            }

            var destinationUrl = this.isGeneral ? `post/notification/destinations/${destination.id}` : `people/${this.personId}/notification/destinations/${destination.id}`;
            var collectionUrl = this.isGeneral ? 'post/notification/destinations' : `people/${this.personId}/notification/destinations`;

            app.api
                .delete(destinationUrl)
                .then(this.destinations.splice(this.destinations.indexOf(destination), 1));
        }
    }
}
</script>

<template>
    <div class="state-post-notification-settings">

        <template v-if="isGeneral">

            <div class="phi-card">
                <div class="destinations">
                    <div v-for="(destination, key) in sortedDestinations" :key="key">
                        <phi-post-notification-destination
                            :isGeneral="true"
                            v-model="destinations[key]"
                            @change="save(destinations[key])"
                            @delete="remove(destinations[key])"
                        ></phi-post-notification-destination>
                    </div>
                </div>
            </div>

        </template>
        <template v-else>

            <template v-for="transport in transports">
                <section v-if="transport.destinations.length" :key="transport.name">

                    <h2>{{ $t('transport.' + transport.name) }}</h2>
                    <div class="phi-card">
                        <div class="destinations">
                            <div
                                v-for="(destination, key) in transport.destinations"
                                :key="destination.id"
                            >
                                <phi-post-notification-destination
                                    v-model="transport.destinations[key]"
                                    @change="save(transport.destinations[key])"
                                    @delete="remove(transport.destinations[key])"
                                ></phi-post-notification-destination>
                            </div>
                        </div>

                        <div class="adder" v-if="transport.name == 'email'">
                            <phi-drawer :open="formIsOpen">
                                <div class="phi-media form">
                                    <div class="phi-media-body">
                                        <phi-input :label="$t('noun.email')"  v-model="newDestination.destination"></phi-input>
                                    </div>
                                    <button class="phi-button phi-media-right" @click="save(newDestination)">{{ $t('action.add') }}</button>
                                </div>
                            </phi-drawer>
                            <div class="phi-media face" @click="formIsOpen = !formIsOpen">
                                <mu-icon class="phi-media-figure" :value="formIsOpen ? 'clear' : 'add'"></mu-icon>
                                <h1 class="phi-media-body" v-text="formIsOpen ? $t('action.cancel') : $t('noun.addEmail')"></h1>
                            </div>
                        </div>
                    </div>

                </section>
            </template>

        </template>



    </div>
</template>

<style lang="scss">
.state-post-notification-settings {
    .adder {
        .phi-media {
            padding: 10px 12px;
            cursor: pointer;
            align-items: center;

            h1 {
                font-size: 1em;
            }

            .phi-media-figure {
                width: 24px;
                opacity: .5;
            }
        }

        .phi-button {
            font-size: .9em;
            padding: 8px 12px;
        }

        .form {
            padding: 16px 12px;

            .phi-input {
                width: 100%;
                margin: 8px 0;
            }

            .phi-media-right {
                margin-left: 1em;
            }
        }
    }
}
</style>